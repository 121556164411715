<template>
  <!-- 智能设备详情页面 -->
  <moe-page title="智能设备详情">
    <moe-describe-list title="智能设备信息" :col="3">
      <moe-describe-item label="设备ID"> {{ equipment.id }} </moe-describe-item>
      <moe-describe-item label="设备名称"> {{ equipment.name }} </moe-describe-item>
      <moe-describe-item label="设备型号"> {{ equipment.productModel }} </moe-describe-item>

      <moe-describe-item label="产品ID"> {{ equipment.productId }} </moe-describe-item>
      <moe-describe-item label="蓝牙广播名称"> {{ equipment.bleName }} </moe-describe-item>
      <moe-describe-item label="控制模组"> {{ moduleComputed }} </moe-describe-item>
      <moe-describe-item label="是否支持5G频段WIFI"> {{ fiveGhzOptions[equipment.fiveGhz] }} </moe-describe-item>
      <moe-describe-item label="设备类型"> {{ typeComputed }} </moe-describe-item>
      <moe-describe-item label="通讯类型"> {{ communicationComputed }} </moe-describe-item>
      <moe-describe-item label="无线类型"> {{ wirelessComputed }} </moe-describe-item>
      <moe-describe-item label="是否支持低功耗模式"> {{ fiveGhzOptions ? '是' : '否' }} </moe-describe-item>
      <moe-describe-item label="PCD版刻"> {{ equipment.pcd }} </moe-describe-item>
      <moe-describe-item label="状态"> {{ equipment.enable ? '启用' : '禁用' }} </moe-describe-item>
      <moe-describe-item label="设备图片">
        <moe-image
          :src="equipment.homeImage"
          width="100px"
          height="100px"
        />
      </moe-describe-item>
      <moe-describe-item label="通用图片">
        <moe-image
          :src="equipment.image"
          width="100px"
          height="100px"
        />
      </moe-describe-item>
      <moe-describe-item label="说明文档">
        <div class="doc-list">
            <a :href="$moe_config.fileUrl + item" class="doc-item" title="点击下载或查看" v-for="(item, index) of equipment.files" :key="index">
              <i class="el-icon-document" /> {{ '说明文档 - ' + (index + 1) }}
            </a>
        </div>
      </moe-describe-item>
      <moe-describe-item label="参数">
        <!-- <div class="df aic mb-10" v-for="(item, key) in equipment.params" :key="key">
          <moe-tag>{{ key }}</moe-tag>
          <div class="ml-10 mr-10">:</div>
          <moe-tag>{{ item }}</moe-tag>
        </div> -->
        <div class="df fdc" style="width: 500px;height: 500px;">
          <moe-table :numberShow="false" :data="data" :params="{ pageNum: 1 }" :mode="false">
            <el-table-column label="key" prop="key" />
            <el-table-column label="value" prop="value" />
          </moe-table>
        </div>
      </moe-describe-item>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'SmartEquipmentDetail',
  data() {
    return {
      equipment: {}, //智能设备信息
      data: [],
      moduleList: {},
      typeList: {},
      communicationList: {},
      wirelessList: {},
      fiveGhzOptions: {
        0: '否',
        1: '是',
        'null': '-',
      }
    };
  },
  computed: {
    moduleComputed() {
      if (!this.equipment.module) {
        return ''
      }

      let module = ''

      for (let key in this.moduleList) {
        if (this.equipment.module === this.moduleList[key]) {
          module = key
          break
        }
      }
      return module
    },
    typeComputed() {
      if (this.equipment.type === '') {
        return ''
      }

      let type = ''

      for (let key in this.typeList) {
        if (this.equipment.type === this.typeList[key]) {
          type = key
          break
        }
      }
      return type
    },
    communicationComputed() {
      if (!this.equipment.communication) {
        return ''
      }

      let communication = ''

      for (let key in this.communicationList) {
        if (this.equipment.communication === this.communicationList[key]) {
          communication = key
          break
        }
      }
      return communication
    },
    wirelessComputed() {
      if (!this.equipment.wireless) {
        return ''
      }

      let wireless = ''

      for (let key in this.wirelessList) {
        if (this.equipment.wireless === this.wirelessList[key]) {
          wireless = key
          break
        }
      }
      return wireless
    }
  },
  created() {
    this.getDeviceModelParams()
    this.getequipmentDetail();
  },
  methods: {
    /**
      * 可用参数
      */
    getDeviceModelParams() {
      this.$moe_api.EQUIPMENT_API.getDeviceModelParams().then((data) => {
        if (data.code === 200) {
          const { module, type, communication, wireless } = data.result
          this.moduleList = module;
          this.typeList = type;
          this.communicationList = communication;
          this.wirelessList = wireless;
        } else {
          this.$moe_msg.error(data.message)
        }
      })
    },
    /**
     * 获取智能设备详情
     **/
    getequipmentDetail() {
      // 调用智能设备API
      this.$moe_api.EQUIPMENT_API.smartEquipmentDetail({ id: this.$route.query.id }).then(
        (data) => {
          if (data.code == 200) {
            let e = data.result;
            let params = {}
            if (e.params) {
              params = JSON.parse(e.params)
              for (let key in params) {
                this.data.push({ key, value: JSON.stringify(params[key]) })
              }
            }

            this.equipment = {
              id: e.id,
              name: e.name,
              productModel: e.productModel,
              productId: e.productId,
              bleName: e.bleName,
              module: e.module,
              type: e.type,
              communication: e.communication,
              wireless: e.wireless,
              pcd: e.pcd,
              fiveGhz: e.fiveGhz,
              params,
              homeImage: e.homeImage,
              image: e.image,
              files: e.files,
            };

          } else {
            this.$moe_msg.error(data.message);
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-list {
  .doc-item {
    margin-bottom: 5px;
    text-decoration: none;
    color: #666;
  }

  a{
    display: block;
  }

  i {
    font-size: 16px;
    padding-right: 5px;
  }
}
</style>
